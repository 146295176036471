@import (inline) '~normalize.css';

html {
    width: 100%;
    height: auto;
    min-height: 100%;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 81.25%;
    background: #181928; /* Old browsers */
    background: linear-gradient(135deg, #181928 0%, #3f4c6b 100%); /* W3C */
    color: #C0C0C8;
}

.logo-wrap {
    max-width: 397px;
    max-height: 68px;

    img {
        width: 100%;
        height: 100%;
    }
}

input[type=text], input[type=password], input[type=email], input[type=url], input[type=tel], select, textarea {
    border: 1px solid transparent;
    padding: 10px 6px;
    width: 100%;
    font-size: 1.5em;
    box-sizing: border-box;
    border-radius: 2px;
    color: black;

    &:focus:not(:disabled) {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 5px rgba(102, 175, 233, 0.6);
        border-color: #66afe9;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
}

textarea {
    font-size: 1.2em;
}

label {
    display: block;
    font-size: 1.2em;
    margin-bottom: 3px;
}

.container {
    margin: 60px auto 40px auto;
    width: 400px;
}

a {
    color: #1FBAD6;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

h2 {
    color: #717396;
    margin-top: 25px;
}


.row {
    margin-top: 15px;
}

button {
    background-color: #1FBAD6;
    color: white;
    border-radius: 2px;
    width: 100%;
    border: none;
    padding: 12px 0;
    box-sizing: border-box;
    font-size: 1.3em;
    cursor: pointer;
}

button:hover {
    background-color: #00c4d6;
}

@media screen and (max-width: 420px) {
    .container {
        width: auto;
        margin: 5px 10px;
    }

}

label.checkbox {
    > input, span {
        vertical-align: middle;
    }

    > span {
        margin-left: 4px;
    }

    cursor: pointer;
}
