#notify-container {
    position: fixed;
    right: 0;
    top: 0;
    margin: 7px;
    padding: 0;
    list-style: none;
    width: 40%;
    min-width: 300px;
    max-width: 600px;
    pointer-events: none;
    z-index: 2147483647;

    .notify-item {
        margin: 0;
        padding: 0;
        text-align: right;

        .notify-message {
            pointer-events: auto;
            cursor: pointer;
        }
    }
}

.notify-message {
    text-align: left;
    display: inline-block;
    background-color: rgb(255, 255, 255);
    color: rgb(68, 68, 68);
    margin: 0 0 4px 0;
    padding: 10px;
    font-family: arial, sans-serif;
    font-size: 13px;
    line-height: 16px;
    white-space: pre-line;
    border: 1px solid rgb(222, 222, 222);
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    border-radius: 2px;

    p {
        margin: 8px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul, ol {
        padding: 0 0 0 25px;
        margin: 0;
        line-height: 1em;
    }

    li {
        // FIXME: not sure why there' still so much of a gap here: https://mpen.xyz/share/2022/02/phpstorm64_2022-02-17_19-21-03.png
        margin: 0;
        padding: 0;
    }

    &.success {
        border-color: rgb(124, 221, 119);
        background-color: rgb(188, 245, 188);
        color: rgb(0, 100, 0);
    }

    &.error, &.invalid {
        border-color: rgb(226, 83, 83);
        background-color: rgb(255, 129, 129);
        color: rgb(255, 255, 255);
    }

    &.info {
        border-color: rgb(59, 173, 214);
        background-color: rgb(120, 197, 231);
        color: rgb(255, 255, 255);
    }

    &.warning {
        border-color: rgb(255, 194, 55);
        background-color: rgb(255, 234, 168);
        color: rgb(130, 98, 0);
    }
}
